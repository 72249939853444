<template>
	<div class="col-12">
		<div class="card card-outline card-navy">
			<div class="card-header">
				<h3 class="card-title">Lista de cheques por pagar</h3>
				<div class="card-tools">
					<button @click="downloadFiles()" class="btn btn-sm btn-outline-secondary mr-1">
						<i class="fa-solid fa-box-archive"></i> Archivo de facturas
					</button>
					<button class="btn btn-outline-success btn-sm mr-2" @click="exportData()">
						<i class="fa fa-file-excel"></i> Exportar
					</button>
					<!-- <button @click="formData()" v-can="'add_checks'" class="btn btn-sm btn-outline-info">
						<i class="fa fa-plus"></i> cheque
					</button> -->
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-7 col-lg-8">
						<label>Buscar :</label>
						<input class="form-control" type="search" v-debounce:1s="list" v-model="name">
					</div>
					<div class="col-md-5 col-lg-4">
						<label>Rango de fechas :</label>
						<date-picker format="YYYY-MM-DD" valueType="format" :range="true" v-model="search.date"></date-picker>
					</div>
					<div class="col-md-4 mt-2">
						<label>Empresa :</label>
						<select v-model="search.company_id" class="form-control">
							<option value="">Todos</option>
							<option v-for="(company, index) in catalogue.companies_buildings" :key="index"
								:value="company.id"> {{ company.name }}
							</option>
						</select>
					</div>

					<div class="col-md-4 mt-2">
						<label>Tipo :</label>
						<select type="text" class="form-control" v-model="search.type_id">
							<option value="">Todos</option>
							<option value="1">Presupuestos</option>
							<option value="2">Gasto recurrente</option>

						</select>
					</div>
					<div class="col-md-4 mt-2">
						<label>Usuario :</label>
						<select type="text" class="form-control" v-model="search.user_id">
							<option value="">Todos</option>
							<option v-for="(user, index) in catalogue.users_profile" :value="user.id" :key="index">
								{{ user.name }} {{ user.last_name }}</option>
						</select>
					</div>
					<div class="col-md-4 mt-2">
						<label>Categoría :</label>
						<select type="text" class="form-control" v-model="search.concept_id">
							<option value="">Todos</option>
							<option v-for="(concept, index) in catalogue.concepts" :value="concept.id" :key="index">
								{{ concept.name }}</option>
						</select>
					</div>
					<div class="col-md-4 mt-2">
						<label>Tipo de saldo</label>
						<select type="text" class="form-control" v-model="search.type_payment_id">
							<option value="">Todos</option>
							<option v-for="(type_payment, index_type_payment) in catalogue.type_payments"
								:value="type_payment.id" :key="index_type_payment">{{
						type_payment.name }}</option>
						</select>
					</div>
					<div class="col-md-4 mt-2">
						<label>Tipo de factura :</label>
						<select class="form-control" v-model="search.biill_type_id">
							<option value="">Todos</option>
							<option v-for="(type, index) in catalogue.bills_types" :value="type.id" :key="index">{{
						type.name }}</option>
						</select>
					</div>
					<div class="col-md-6 mt-2">
						<label>Estatus :</label>
						<select class="form-control" v-model="search.status">
							<option value="">Todos</option>
							<option value="1">Por aprobar</option>
							<option value="2">Cancelados</option>

						</select>
					</div>
					<div class="col-md-6 mt-2">
						<label>Razón Social :</label>
						<select class="form-control" v-model="search.billing_data_id">
							<option value="">Todos</option>
							<option v-for="(type, index) in catalogue.all_billing_data" :value="type.id" :key="index">
								{{ type.business_name }} - {{ type.rfc }}
							</option>
						</select>
					</div>

				</div>
				<br>
				<div class="row">

					<div class="col-md-12">
						<ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
							<li class="nav-item">
								<a class="nav-link active" @click="search.authorized = 1" data-toggle="pill" role="tab"
									aria-selected="true">Por validar</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" @click="search.authorized = 2" data-toggle="pill" role="tab"
									aria-selected="false">Validados</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>ID</th>
								<th style="width: 40%;">Datos</th>
								<th style="width: 140px;">Monto</th>
								<th style="width: 140px;">Por pagar</th>
								<th>Fecha de creación</th>
								<th>Fecha de factura</th>
								<th class="text-center">Opciones</th>
								<th class="text-center" v-can="'approve_checks'">
									<button class="btn btn-sm btn-info" @click="selectAll">
										<i class="fa-solid fa-check-double"></i>
									</button>
								</th>
							</tr>
						</thead>
						<tbody v-if="load">
							<tr>
								<td align="center" colspan="8">
									<i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
									<h5>Cargando</h5>

								</td>
							</tr>
						</tbody>
						<tbody v-else-if="checks.total > 0">
							<tr :class="[(buy.authorization_user_id ? 'bg-info' : ''), (buy.cancellation_message ? 'bg-gray' : ''), , (buy.id == selected ? 'selected' : '')]"
								v-for="(buy, index) in checks.data" :key="index">
								<td>{{ buy.id }}</td>
								<td class="text-left">
									<!-- Tipo -->
									<span v-if="buy.type_id == 1"> Presupuesto </span>
									<span v-else> Gasto </span>
									<br>

									<!-- Proveedor -->
									<strong>Usuario: </strong>
									<span v-if="buy.provider"> {{ buy.provider.name }} {{ buy.provider.last_name
										}}</span>
									<span v-else>Sin Usuario</span>
									<br>

									<!-- Concepto -->
									<strong>Concepto : </strong>
									<span v-if="buy.bill"> {{ buy.bill.name }}</span>
									<span v-else>Sin Concepto</span>
									<br>


									<!-- Empresa -->
									<strong>Empresa: </strong>
									<span v-if="buy.bill">
										<span v-if="buy.bill.company">
											{{ buy.bill.company.name }}
										</span>
									</span>
									<span v-else>Sin Empresa</span>
									<br>
									<br>

									<!-- Tipo -->
									<strong>Tipo: </strong>
									<span v-if="buy.type_payment"> {{ buy.type_payment.name }}</span>
									<span v-else>Sin tipo</span>
									<br>

									<!-- Categoria -->
									<strong>Categoría : </strong>
									<span v-if="buy.bill"> {{ buy.bill.name }}</span>
									<span v-else>Sin Concepto</span>
									<br>
									<hr>
									<strong>Datos de facturación </strong>
									<br>

									<!-- Razón social -->
									<span v-if="buy.bill">
										<span v-if="buy.bill.user_bill">
											{{ buy.bill.user_bill.business_name }}
										</span>
										<span v-else>Sin Razón Social</span>
									</span>
									<span v-else>Sin Razón Social</span>
									<br>

									<!-- Folio Factura -->
									<strong>Folio : </strong>
									<span v-if="buy.bill">
										<span v-if="buy.bill.folio && buy.bill.folio != 'null'">
											{{ buy.bill.folio }}
										</span>
										<span v-else>Sin Folio</span>
									</span>
									<span v-else>Sin Folio</span>
									<br>



									<!-- RFC -->
									<span v-if="buy.bill">
										<span v-if="buy.bill.user_bill">
											{{ buy.bill.user_bill.rfc }} |
											<a class="btn btn-info btn-sm" target="_blank"
												v-if="buy.bill.user_bill.cedula"
												:href="`${globalUrlFiles}${buy.bill.user_bill.cedula}`">
												<i class="fa-regular fa-file-pdf"></i> Ver cédula fiscal
											</a>
											<small v-else class="btn_now badge badge-secondary"> Sin caratula
												fiscal</small>
										</span>
										<span v-else>Sin RFC</span>
									</span>
									<span v-else>Sin RFC</span>
									<br>

									<strong>Aprobado por :</strong><br>
									<span v-if="buy.bill">
										<span v-if="buy.bill.provider">
											{{ buy.bill.provider.name }} {{ buy.bill.provider.last_name }}
											{{ buy.bill.approved | toDateTime }}
											<button target="_blank" class="btn m-1 btn-outline-secondary btn-sm"
												@click="approveBill(buy.bill)">
												<i class="fas fa-file-pdf "> </i> Ver reporte
											</button>
										</span>
										<span v-else>S/D</span>
									</span>
									<span v-else>S/D</span>
									<br>

									<hr>
									<strong>Cuenta Bancaria </strong>
									<br>

									<!-- Banco -->
									<span v-if="buy.bill">
										<span v-if="buy.bill.bank_account_providers">
											{{ buy.bill.bank_account_providers.bank.name }}
										</span>
										<span v-else>Sin Banco</span>
									</span>
									<span v-else>Sin Banco</span>
									<br>

									<!-- Cuenta -->
									<span v-if="buy.bill">
										<span v-if="buy.bill.bank_account_providers">
											{{ buy.bill.bank_account_providers.account_number }} |
											<a class="btn btn-info btn-sm" target="_blank"
												v-if="buy.bill.bank_account_providers.file"
												:href="`${globalUrlFiles}${buy.bill.bank_account_providers.file}`">
												<i class="fa-regular fa-file-pdf"></i> Ver caratula de banco
											</a>
											<small v-else class="btn_now badge badge-secondary"> Sin caratula de
												banco</small>
										</span>
										<span v-else>Sin Cuenta</span>
									</span>
									<span v-else>Sin Cuenta</span>
									<br>

									<!-- Concepto -->
									<strong>Concepto : </strong>
									<span v-if="buy.bill"> {{ buy.bill.name }}</span>
									<span v-else>Sin Concepto</span>
									<br>

									<!-- Moneda -->
									<strong>Moneda : </strong>
									<span v-if="buy.bill"> {{ buy.bill.currency.name }}</span>
									<span v-else>S/D</span>
									<br>

									<!-- Descripción -->
									<strong>Descripción : </strong>
									<span v-if="buy"> {{ buy.description }}</span>
									<br>

									<!-- Mensaje de cancelación -->
									<hr v-if="buy.cancellation_message">
									<strong v-if="buy.cancellation_message">Comentarios de cancelación : </strong>
									<br>
									<span v-if="buy.cancellation_message"> {{ buy.cancellation_message }}</span>
									<hr v-if="buy.cancellation_message">

									<!-- Mensaje de aceptación -->
									<hr v-if="buy.authorization_message">
									<strong v-if="buy.authorization_message">Comentarios de aprobación : </strong>
									<br>
									<span v-if="buy.authorization_message"> {{ buy.authorization_message }}</span>
									<hr v-if="buy.authorization_message">



									<button @click="formData(buy)" v-can="'edit_checks'"
										class="btn btn-outline-primary m-1 btn-sm">
										<i class="fas fa-edit "> </i> Editar
									</button>
									<button @click="formData(buy, false)" class="btn m-1 btn-outline-primary btn-sm">
										<i class="fas fa-eye "> </i> Ver más
									</button>
									<button v-if="buy.pay.length == 0" type="button" @click="deleted(buy.id)"
										v-can="'deleted_checks'" class="btn-outline-danger btn m-1 btn-sm ">
										<i class="fa fa-trash"></i> Eliminar
									</button>


								</td>
								<td class="text-right">
									{{ buy.amount | toCurrency }}
								</td>
								<td class="text-right">
									{{ buy.amount_payable | toCurrency }}
								</td>
								<td>
									{{ buy.created_at | toDateTime }}
								</td>
								<td>
									<span v-if="buy.bill">
										{{ buy.bill.date | toDateTime }}
									</span>
									<span v-else>
										S/F
									</span>

								</td>
								<td class="text-center">

									<button v-if="buy.bill" @click="showBill(buy.bill)"
										class="btn btn-outline-info m-1 btn-sm">
										<i class="fa-solid fa-file-invoice-dollar"> </i> Información de factura
									</button>

									<!-- <button v-if="buy.pay" @click="formListPays(buy)" v-can="'edit_checks'"
										class="btn btn-outline-warning m-1 btn-sm">
										<i class="fa-solid fa-hand-holding-dollar"> </i> Información de pagos
									</button> -->

									<button v-if="!buy.authorization_user_id" type="button" @click="approve(buy.id)"
										v-can="'approve_checks'" class="btn-outline-warning btn m-1 btn-sm ">
										<i class="fa fa-check"></i> Aprobar
									</button>

									<button v-if="buy.type_id == 1" @click="showBudget(buy.budget.id)" type="button"
										class="btn btn-sm  m-1 btn-outline-light ">
										<i class="fa-solid fa-coins mr-1"></i>Ver presupuesto
									</button>

									<button v-if="buy.type_id == 2 && buy.buy.payment_period_id !== 5"
										@click="viewPay(buy.buy_id)" type="button"
										class="btn btn-sm  m-1 btn-outline-light ">
										<i class="fa-solid fa-basket-shopping mr-1"></i>Ver compra recurrente
									</button>



									<button v-if="buy.type_id == 2 && buy.buy.buy_id" @click="viewPay(buy.buy.buy_id)"
										type="button" class="btn btn-sm  m-1 btn-outline-light ">
										<i class="fa-solid fa-basket-shopping mr-1"></i>Ver compra recurrente
									</button>

									<div class="d-inline" v-if="buy.bill">
										
										<a v-tooltip.bottom-start="'Tooltip on bottom'" v-if="buy.bill.file"
											target="_blank" class="btn m-1 btn-outline-secondary btn-sm"
											:href="`${globalUrlFiles}${buy.bill.file}`">
											<i class="fas fa-file-pdf "> </i> Archivo PDF
										</a>
										<button v-tooltip.bottom-start="'Tooltip on bottom'" v-if="buy.bill.xml"
											class="btn m-1 btn-outline-secondary btn-sm"
											@click="downloadFile('xml', buy.bill.id)">
											<i class="fas fa-file "> </i> Archivo XML
										</button>

										<button type="button" v-if="buy.bill" @click="showChecks(buy.bill)"
											class="btn m-1 btn-outline-success btn btn-sm ">
											<i class="fa-solid fa-receipt"></i> Ver cheques de la factura
										</button>
									</div>

									<div class="d-inline" v-if="buy.bill">
										<button @click="newCredit(buy.bill, buy.bill.credit)" v-if="buy.bill.credit"
											class="btn m-1 btn-sm btn-outline-info">
											<i class="fa-solid fa-comment-dollar mr-1"></i>Ver nota de crédito
										</button>
									</div>

									<div class="d-inline" v-if="buy.bill">
										<button type="button" v-if="buy.bill.pety_cash" @click="showPettyCash(buy.bill.pety_cash)"
											class="btn m-1 btn-outline-success btn btn-sm ">
											<i class="fa-solid fa-cash-register"></i> Ver movimiento
										</button>
									</div>

								</td>
								<th class="text-center" v-can="'approve_checks'">
									<input type="checkbox" class="form-control form-control-sm" :value="buy.id"
										v-model="Arrauthorized">
								</th>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="8">Sin resultados.</td>
							</tr>
						</tbody>
						<tfoot v-if="checks.total > 0 && load == false">
							<tr class="table-secondary">
								<td colspan="2">
									Total :
								</td>
								<td class="text-right">
									{{ sumdata()[0] | toCurrency }}
								</td>
								<td class="text-right">
									{{ sumdata()[1] | toCurrency }}
								</td>
								<td colspan="4"></td>
							</tr>
						</tfoot>
					</table>
					<button class="btn btn-outline-info btn-sm"
						v-if="search.authorized == 1 && Arrauthorized.length > 0" @click="aprovedSelected"
						style="float: right;">
						<i class="fa-solid fa-check-to-slot"></i> Aprobar seleccionados
					</button>
					<pagination v-model="page" :records="checks.total" :per-page="checks.per_page" @paginate="list" />
				</div>
			</div>
		</div>
		<ModalFormComponent :prop_edit="editCheck" />
		<ModalFormComponentPays :prop_edit="false" />
		<ModalFormComponentBudgets />
		<ModalFormComponentBuy />
		<ModalBudgetsTable />
		<ModalBuysTable />
		<ModalTableComponentPays />
		<ModalFormBill :prop_edit="false" />
		<ModalDownloadFiles />
		<ModalFormApproveComponent />
		<ModalBuysRelationComponent />
		<ModalCredit :prop_edit="false" />
		<ModalChecksBillSummary />
		<ModalFormComponentBudgetsPetty :prop_edit="false" />

	</div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
// import pagination from 'laravel-vue-pagination';
import ModalCredit from '@/components/bill/ModalCreditComponent.vue';
import ModalBuysRelationComponent from '@/components/buy/ModalBuysRelationComponent.vue';
import ModalFormApproveComponent from '@/components/bill/ModalFormApproveComponent.vue';
import ModalDownloadFiles from '@/components/bill/ModalDownloadFilesComponent.vue';
import request from '@/services/request'
import ModalFormComponent from '@/components/checks/ModalFormComponent.vue';
import ModalFormComponentPays from '@/components/pays/ModalFormComponent.vue';
import ModalFormComponentBudgets from '@/components/budgets/ModalFormComponent.vue'
import ModalFormComponentBuy from '@/components/buy/ModalFormComponent.vue'
import ModalBudgetsTable from '@/components/budgets/ModalTableComponent.vue'
import ModalBuysTable from '@/components/buy/ModalTableComponent.vue'
import ModalTableComponentPays from '@/components/pays/ModalTableComponent.vue';
import ModalFormBill from '@/components/bill/ModalFormComponent.vue';
import Pagination from 'vue-pagination-2';
import Catalogue from '@/services/catalogue';
import ModalChecksBillSummary from '@/components/checks/ModalChecksBillSummaryComponent.vue'
import ModalFormComponentBudgetsPetty from '@/components/petty_cash/ModalFormPettyCashComponent.vue'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
	name: "companies-table",
	components: {
		ModalFormComponent,
		ModalFormComponentPays,
		ModalFormComponentBudgets,
		ModalFormComponentBuy,
		ModalBudgetsTable,
		ModalBuysTable,
		ModalTableComponentPays,
		ModalFormBill,
		ModalDownloadFiles,
		Pagination,
		DatePicker,
		ModalFormApproveComponent,
		ModalBuysRelationComponent,
		ModalCredit,
		ModalChecksBillSummary,
		ModalFormComponentBudgetsPetty
	},
	data() {
		return {
			checks: {
				data: []
			},
			name: '',
			editCheck: true,
			search: {
				billing_data_id:'',
				date: '',
				company_id: '',
				search: '',
				type_id: '',
				user_id: '',
				concept_id: '',
				type_payment_id: '',
				biill_type_id: '',
				status: '',
				authorized: 1,
			},
			selected: '',
			Arrauthorized: [],
			page: 1,
			catalogue: new Catalogue(),
			load: false,
		}
	},
	mounted() {
		this.catalogue.get_companies_buildings();
		this.catalogue.get_users_profile(2);
		this.catalogue.get_concepts();
		this.catalogue.get_type_payments();
		this.catalogue.get_bills_types();
		this.catalogue.get_all_billing_data();
		this.list();
		this.setHeaderTitle('Cheque');
		let value = localStorage.getItem('bill_data');
		if (value) {
			this.formData();
		}
		let id = this.$route.query.id;
		if (id) {
			this.showSelected(id);
		}
	},
	created() {
		Event.$on("event-load-checks", () => {
			this.list();
		});
	},
	methods: {
		showPettyCash(petty = false) {
			Event.$emit('event-show-addPettyCash', petty);
		},
		showChecks(bill = {}) {
			Event.$emit('event-show-listChecksBillsSummary', bill);
		},
		newCredit(bill, credit) {
			Event.$emit('event-show-addBillCredit', bill, credit);
		},
		approveBill(bill, edit = false) {
			Event.$emit('event-show-addBillApprove', bill, edit);
		},
		sumdata() {
			let sum1 = 0;
			let sum2 = 0;
			let sum3 = 0;
			if (this.checks) {
				this.checks.data.forEach(function (buy) {
					sum1 += parseFloat(buy.amount);

					sum2 += parseFloat(buy.amount_payable);

				});
			}

			return [sum1, sum2, sum3];
		},
		downloadFiles() {
			let filter = this.search;
			filter.type = 'checks';
			Event.$emit('event-show-downloadFiles', filter);
		},
		downloadFile(type, id) {
			let url = this.globalUrlFiles + '/bills/download_file?id=' + id + '&type=' + type;
			window.open(url, '_blank');

		},
		downloadRecibe(id) {
			window.open(this.globalUrlFiles + '/bills/pdf/' + id, '_blank');
		},
		selectAll() {
			let selected = this.Arrauthorized;
			this.checks.data.forEach(element => {
				if (!selected.includes(element.id)) {
					selected.push(element.id);
				}
			});
			this.Arrauthorized = selected;
		},
		aprovedSelected() {
			let tit = 'Aprobar seleccionados';
			let txt = 'Se aprobaran los cheques seleccionados';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then(async (result) => {
				if (result.isConfirmed) {
					let selected = this.Arrauthorized;
					let _endpoint = '/checks_approve_selected';
					let form = {
						selected: selected
					};
					try {
						let response = await request.post(_endpoint, form).then(function (response) { return response; });
						this.list();
						Event.$emit('event-check-cancel');
						Event.$emit('event-load-checks-approved');
						this.successNotification(response.data.message);
					} catch (error) {
						this.errors = await this.errorNotification(error)
					}

				}
			});
		},
		exportData() {
			let filter = this.globalUrlFiles + '/checks/export?' + new URLSearchParams(this.search).toString();
			filter += '&user_login=' + this.authUser.id;
			window.open(filter, '_blank');
		},
		showBill(bill = {}) {
			Event.$emit('event-show-addBill', bill);
		},
		formData(check = {}, edit = true) {
			this.editCheck = edit;
			console.log(check);
			Event.$emit('event-show-addChecks', check);
		},
		formListPays(check) {
			Event.$emit('event-show-listPays', check);
		},
		formDataPays(pay) {
			Event.$emit('event-show-addPays', pay.check_id, pay);
		},
		showBudget(id) {
			Event.$emit('event-load-budget', id);
		},
		viewPay(id) {
			Event.$emit('event-show-BuysRelationID', id);
		},
		deleted: function (id) {
			let tit = 'Eliminar Cheque';
			let txt = 'Seguro que quiere eliminar el Cheque';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		approve: function (id) {
			// let tit = 'Aprobar Cheque';
			// let txt = 'Seguro que quiere aprobar el Cheque';

			let vm = this;

			this.$swal({
				title: 'Comentario de aprobación',
				input: 'text',
				icon: 'warning',
				inputAttributes: {
					autocapitalize: 'off'
				},
				showCancelButton: true,
				confirmButtonText: 'Guardar',
				showLoaderOnConfirm: true,
				preConfirm: async (message) => {
					console.log(message);
					let form = {
						id: id,
						message: message,
					};
					let _endpoint = '/checks_approve';
					try {
						let { data } = await request.postMedia(_endpoint, form)
						if (data) {
							console.log(data);
							await vm.successNotification(data.message);
							await vm.list();
							Event.$emit('event-check-cancel');
							Event.$emit('event-load-checks-approved');

						}
					} catch (error) {
						vm.errors = {};
						if (error.response) {
							vm.errors = vm.errorNotification(error);
						}
					}
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {
					console.log(result);
				}
			})



			// this.$swal({
			// 	title: tit,
			// 	text: txt,
			// 	icon: 'warning',
			// 	showCancelButton: true,
			// 	confirmButtonColor: '#3085d6',
			// 	cancelButtonColor: '#d33',
			// 	confirmButtonText: 'Ok',
			// 	cancelButtonText: 'Cancelar'
			// }).then(async (result) => {
			// 	if (result.isConfirmed) {
			// 		let _endpoint = '/checks_approve';
			// 		let form = {
			// 			id: id
			// 		};
			// 		try {
			// 			let response = await request.post(_endpoint, form).then(function (response) { return response; });
			// 			this.list();
			// 			this.successNotification(response.data.message);
			// 		} catch (error) {
			// 			this.errors = await this.errorNotification(error)
			// 		}
			// 	}
			// });
		},
		async callDeleted(id) {
			let _endpoint = '/checks/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				Event.$emit('event-load-checks-approved');
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport() {
			this.$modal.show('example');
		},
		showSelected(id = false) {
			if (id) {
				this.selected = id;
				this.search.status = 2;
			} else {
				this.selected = '';
				this.search.status = '';
			}
		},
		async list(page = this.page) {
			this.load = true;
			let service = request;
			this.search.search = this.name;
			let search = this.search;

			let _endpoint = '/checks';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.checks = data;
					this.load = false;
				}
			} catch (error) {
				console.log(error);
				this.load = false;
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			resetPassword: 'auth/resetPass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
	watch: {
		'name': {
			handler: function (val) {
				if (val == '') {
					this.list();
				}
			},
			deep: true
		},
		"search": {
			handler: function () {
				this.list();
			},
			deep: true
		},
		'$route.query': {
			handler: function (params) {
				if (params) {
					this.showSelected(params.id);
				}
				this.list();
			},
			deep: true,
			immediate: true,
		}
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	color: #ffffff;
	background-color: #214d83;
	border-color: #dee2e6 #dee2e6 #fff;
}

.selected {
	color: #000;
	font-weight: bold;
	animation: blinkingText 4s infinite;
}

@keyframes blinkingText {
	0% {
		border: 3px solid #36a590;
	}

	25% {
		border: 3px solid #dee2e6;
	}

	50% {
		border: 3px solid #36a590;
	}

	75% {
		border: 3px solid #dee2e6;
	}

	100% {
		border: 3px solid #36a590;
	}
}
</style>
