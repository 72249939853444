<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                tenant_id: '',
                type_id: 2,
                concept: '',
                number: '',
                service_id: '',
                serv_id: '',
                service_type: '',
                file: '',
                xml: '',
                folio: '',
                amount: '',
            },
            cataloguePays: [],
            servicesExtras: [],
            file: '',
            fileName: '',
            xml: '',
            xmlName: '',
            tenant: {},
            service_type:'',
            send: false,
            load: false,
        }
    },
    async mounted() {
        // this.catalogue.get_types_bill_number();
    },
    methods: {
        dataPay(id = 0) {
            let concept = '';
            let data = this.tenant;

            let nowDate = this.moment(data.star_date);
            let dateConcept = nowDate.format('MMMM [de] YYYY');


            if (id == 0) {
                if (data.department) {
                    concept += `${data.department.number} en la planta ${data.department.level}`;
                }
                concept += ` del mes de ${dateConcept}`;
                this.form.serv_id = 0;
                this.form.service_type = 1;
            } else {
                let arr = id.split('-');
                this.form.serv_id = arr[0];
                this.form.service_type = arr[1];
                if (arr[1] == 1) {
                    const objetoEspecifico = this.cataloguePays.find(item => item.id == arr[0]);
                    concept += objetoEspecifico.name;
                    concept += ` en  ${dateConcept}`;

                } else {
                    const objetoEspecifico = this.servicesExtras.find(item => item.id == arr[0]);
                    concept += objetoEspecifico.name;
                    concept += ` del mes de ${dateConcept}`;

                }
            }

            if (data.building) {
                if (data.building.address != '' && data.building.address != 'undefined') {
                    concept += `, del inmueble ubicado en ${data.building.address}`;
                }
            }


            this.form.concept = concept;
        },
        closedModal() {
            this.form.id = '';
            this.form.type_id = 2;
            this.form.item_id = '';
            this.form.tenant_id = '';
            this.form.number = '';
            this.form.service_id = '';
            this.form.service_type = '';
            this.form.serv_id = '';
            this.form.file = '';
            this.form.concept = '';
            this.form.folio = '';
            this.form.amount = '';
            this.form.xml = '';
            this.send = false;
            this.load = false;
            this.fileName = '';
            this.xmlName = '';
            this.file = '';
            this.xml = '';
            this.tenant = {};
            this.service_type = '';

            this.$modal.hide('addNumberServiceTenant');
        },
        catch_file(event) {
            if (event.target.files[0].type == "application/pdf") {
                this.form.file = event.target.files[0];
                this.fileName = event.target.files[0].name;
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos PDF',
                });
            }
        },
        catch_xml(event) {
            console.log(event.target.files[0].type);
            if (event.target.files[0].type == "text/xml") {
                this.form.xml = event.target.files[0];
                this.xmlName = event.target.files[0].name;
                this.catchDataXML(event.target.files[0]);

            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos XML',
                });
            }
        },
        async catchDataXML(file) {
            console.log(file);
            const data_form = new FormData();
            data_form.append('xml', file);

            let _endpoint = `/bill_load_xml`;

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    console.log(data);
                    // if (data.in_month == true) {
                    this.form.folio = data.Folio;
                    this.form.amount = data.Total;
                   
                }
            } catch (error) {
                this.send = false;
                this.errors = {};
                if (error.response) {

                    this.errors = await this.errorNotification(error);
                }
            }

        },
        async saveNumberBill() {
            this.send = true;

            const data_form = new FormData();
            data_form.append('id', this.form.id)
            data_form.append('type_id', this.form.type_id)
            data_form.append('tenant_id', this.form.tenant_id)
            data_form.append('service_id', this.form.service_id)
            data_form.append('number', this.form.number)
            data_form.append('file', this.form.file);
            data_form.append('xml', this.form.xml);
            data_form.append('concept', this.form.concept);
            data_form.append('folio', this.form.folio);
            data_form.append('amount', this.form.amount);
            data_form.append('service_type', this.form.service_type)


            let _endpoint = '/tenant_service_bill_number';
            if (this.form.id != "") {
                data_form.append('_method', 'PUT');
                _endpoint = `/tenant_service_bill_number/${this.form.id}`;
            }

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-debts');
                }
            } catch (error) {
                this.send = false;
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }

        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    created() {
        Event.$on("event-show-addNumberServiceTenants", async (data, type, number) => {

            console.log(data);
            console.log(type);
            console.log(number);
            this.tenant = data;
            this.service_type = type;    
                

            if (data.id) {
                this.cataloguePays = data.services_extras_unavailable;
                this.servicesExtras = data.services_extras;
            }

            this.form.tenant_id = data.id;

            if (number) {
                this.form.id = number.id;
                this.form.tenant_id = number.tenant_id;
                this.form.number = number.number;
                this.form.type_id = 2;
                this.form.concept = number.concept;
                this.form.folio = number.folio;
                this.form.amount = number.amount;

                if (number.file) {
                    this.file = number.file;
                }
                if (number.xml) {
                    this.xml = number.xml;
                }

                if (number.service_id == 0) {
                    this.form.service_id = number.service_id;
                } else {
                    this.form.service_id = `${number.service_id}-${number.service_type}`;
                }
            }

            if(type == 'services_extras_unavailable'){
                this.form.type_id = 1;
            }

            this.$modal.show('addNumberServiceTenant');

        })
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
    watch: {
        'form.service_id'(nVal) {
            console.log(nVal);
            if (nVal != '') {
                this.dataPay(nVal);
            }
        },
        deep: true
    },

}
</script>
<template>
    <modal name="addNumberServiceTenant" :width="'80%'" :maxHeight="300" :height="'auto'" v-bind:click-to-close="false">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveNumberBill" method="post" id="formTax" enctype="multipart/form-data">
            <button :disabled="send" type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                    class="fa-solid fa-xmark"></i> </button>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Agregar número de factura</h5>
            </div>
            <div class="modal-body">
                <div class="row">

                    <div class="col-md-6 mt-3">
                        <label>Item</label>
                        <select class="form-control" v-model="form.service_id" :disabled="!prop_edit"
                            required="required" v-if="service_type != 'services_extras_unavailable'">
                            <option value="">Selecciona un item</option>
                            <option value="0">
                                Renta
                                <span
                                    v-for="(item_other, index_other) in servicesExtras.filter(item => item.pivot.group_bill != 0)"
                                    :key="index_other">
                                    , {{ item_other.name }}
                                </span>
                            </option>                          
                            <option v-show="item.pivot.group_bill == 0" :value="`${item.id}-2`"
                                v-for="(item, index) in servicesExtras" :key="index">{{ item.name }}
                            </option>
                        </select>

                        <select class="form-control" v-model="form.service_id" :disabled="!prop_edit"
                            required="required" v-if="service_type == 'services_extras_unavailable'">
                            <option value="">Selecciona un item</option>                           
                            <option :value="`${item.id}-1`" v-for="(item, index) in cataloguePays" :key="index">{{
                                item.name }}
                            </option>                          
                        </select>
                        <div v-if="errors && errors.service_id" class="text-danger">{{ errors.service_id[0] }}</div>
                    </div>

                    <div class="col-md-6 mt-3">
                        <label>Número de factura</label>
                        <input class="form-control" v-model="form.number">
                        <div v-if="errors && errors.number" class="text-danger">{{ errors.number[0] }}</div>
                    </div>

                    <div class="col-md-12 mt-3">
                        <label>Concepto</label>
                        <input class="form-control" v-model="form.concept">
                        <div v-if="errors && errors.concept" class="text-danger">{{ errors.concept[0] }}</div>
                    </div>

                    <div class="col-md-6 mt-3">
                        <label>PDF</label><br>
                        <div class="custom-file" :style="file != '' ? 'width: 87%' : 'width: 100%'">
                            <input :disabled="!prop_edit" type="file" class="custom-file-input"
                                :class="file != '' ? 'pr-5' : ''" id="customFile" ref="file" @change="catch_file">
                            <label class="custom-file-label text-left" for="customFile">{{ fileName }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${file}`" v-if="file != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>

                    </div>
                    <div class="col-md-6 mt-3">
                        <label>XML</label><br>
                        <div class="custom-file" :style="xml != '' ? 'width: 87%' : 'width: 100%'">
                            <input :disabled="!prop_edit" type="file" id="fileXML" class="custom-file-input"
                                :class="xml != '' ? 'pr-5' : ''" ref="file" @change="catch_xml">
                            <label class="custom-file-label text-left" for="customFile">{{ xmlName }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${xml}`" v-if="xml != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                class="fa-regular fa-file"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.xml" class="text-danger">{{ errors.xml[0] }}</div>
                    </div>


                    <div class="col-md-6 mt-3">
                        <label>Folio</label>
                        <input class="form-control" :disabled="!prop_edit || form.type_id == 2" v-model="form.folio">
                        <div v-if="errors && errors.folio" class="text-danger">{{ errors.folio[0] }}</div>
                    </div>

                    <div class="col-md-6 mt-3">
                        <label>Monto</label>
                        <money :disabled="!prop_edit || form.type_id == 2" class="form-control" v-model="form.amount"></money>
                        <div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}</div>
                    </div>


                    <div class="col-md-12">
                        <hr>
                        <button :disabled="send" v-if="prop_edit" type="submit"
                            class="btn float-right btn-outline-success">
                            <spam v-if="send">
                                <i class="fa-solid fa-circle-notch fa-spin"></i> Guardando
                            </spam>
                            <spam v-else>
                                <i class="fas fa-save"></i> Guardar
                            </spam>
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>

                </div>
            </div>
        </form>
    </modal>
</template>